document.addEventListener('DOMContentLoaded', () => {
  const swiper = document.querySelector('.swiper');
  const isMobile = window.matchMedia('(max-width: 767px)').matches;

  if (isMobile || !swiper) {
    return;
  }

  const leftBtn = swiper.querySelector('.swiper__btn:first-of-type');
  const rightBtn = swiper.querySelector('.swiper__btn:last-of-type');
  const scrollingList = swiper.querySelector('.swiper__list');

  scrollingList.addEventListener('scroll', adjustScrollControls);
  window.addEventListener('resize', adjustScrollControls);
  leftBtn.addEventListener('click', scrollToLeft);
  rightBtn.addEventListener('click', scrollToRight);

  adjustScrollControls();

  function scrollToLeft() {
    scrollingList.scrollTo({
      left: scrollingList.scrollLeft - scrollingList.offsetWidth,
      behavior: 'smooth',
    });
  }

  function scrollToRight() {
    scrollingList.scrollTo({
      left: scrollingList.scrollLeft + scrollingList.offsetWidth,
      behavior: 'smooth',
    });
  }

  function adjustScrollControls() {
    const isRightEnd =
      Math.round(scrollingList.scrollLeft) >=
      scrollingList.scrollWidth - scrollingList.offsetWidth - 1;
    const isLeftEnd = Math.round(scrollingList.scrollLeft) <= 0;

    rightBtn.toggleAttribute('disabled', isRightEnd);
    leftBtn.toggleAttribute('disabled', isLeftEnd);
  }
});

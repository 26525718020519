document.addEventListener('DOMContentLoaded', () => {
  const demoElements = document.querySelectorAll('.js-demo');
  demoElements.forEach(el => el.addEventListener('click', onDemoClick));

  const contactElements = document.querySelectorAll('.js-contact');
  contactElements.forEach(el => el.addEventListener('click', onContactClick));

  const clientElements = document.querySelectorAll('.js-client');
  clientElements.forEach(el => el.addEventListener('click', onClientClick));
});

function onContactClick(event) {
  const tool = event.currentTarget.dataset.tool;
  const domain = new URL(
    event.currentTarget.getAttribute('href')
  ).hostname.replace('www.', '');
  window.fbq('track', 'Lead');
  window.rdt('track', 'Lead');
  window.gtag('event', 'contact', {
    contact_tool: tool,
    link_domain: domain,
  });
}

function onDemoClick() {
  window.gtag('event', 'demo');
  window.fbq('trackCustom', 'demo');
  window.rdt('track', 'Custom', { customEventName: 'demo' });
}

function onClientClick() {
  window.gtag('event', 'demo');
  window.fbq('trackCustom', 'demo');
  window.rdt('track', 'Custom', { customEventName: 'demo' });
}

document.addEventListener('DOMContentLoaded', () => {
  /* Adding a link to the home page instead of a link to the current page in the footer */
  const currentPage = window.location.pathname;
  const footerLegal = document.querySelector('.footer__legal');
  const currentLink = footerLegal.querySelector(`a[href*="${currentPage}"]`);

  if (currentPage !== '/' && currentPage !== '/index.html' && currentLink) {
    footerLegal.removeChild(currentLink);
    const homeLink = document.createElement('a');
    homeLink.href = '/';
    homeLink.text = 'Home';
    footerLegal.insertAdjacentElement('afterbegin', homeLink);
  }
});
